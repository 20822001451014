/*-------------------------------------------------------*\
    Helper classes
/*-------------------------------------------------------*/
div#site-content {
	background: #f7f7f7;
}
.u-center {

	text-align: center;

}

.transition {
	opacity: 0;
@include transition(all, .5s, ease-in-out);
}